import axios from 'axios';
import { domain } from './domain'

export const companyQuery = (data) => {
    const requestOptions = {
        method : 'POST',
        headers : { 
            'Content-type' : 'application/json',
        },
        data
    }
    return axios(domain+'/company/query',requestOptions)
        .then( response => { 
            if(response.data.code){
                return response
            }else{
                throw new Error('Bağlantı hatası!')
            }})
        .catch( error => { return error })
}

export const getDistributors = (token,data) => {
    const requestOptions = {
        method : 'POST',
        headers : { 
            'Content-type' : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        data
    }
    return axios(domain+'/company/distributors',requestOptions)
        .then( response => { 
            if(response.data.code){
                return response
            }else{
                throw new Error('Bağlantı hatası!')
            }})
        .catch( error => { return error })
}

export const createCompany = (token,data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json', 
            'Authorization' : 'Bearer ' + token
        },
        data,
    };
    return axios(domain + '/company/usercreate', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getCompaniesByAdmin = (token, data) => {
    const requestOptions = {
        method : 'POST',
        headers : { 
            'Content-type' : 'application/json',
            'Authorization' : 'Bearer ' + token,
        },
        data
    }
    return axios(domain+'/company/getbyadmin',requestOptions)
        .then( response => { 
            if(response.data.code){
                return response
            }else{
                throw new Error('Bağlantı hatası!')
            }})
        .catch( error => { return error })
}

export const companyTypeQuery = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json', 
        },
        data,
    };
    return axios(domain + '/company/typequery', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const updateCompany = (token,data) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-type': 'application/json', 
            'Authorization' : 'Bearer ' + token
        },
        data,
    };
    return axios(domain + '/company/byadmin', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getCompanyByUrl = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json', 
        },
        data
    };
    return axios(domain + '/company/getbyurl', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getCompanyProducts = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json', 
        },
        data
    };
    return axios(domain + '/company/getproducts', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const checkUser = (token,data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json', 
            'Authorization' : 'Bearer ' + token
        },
        data,
    };
    return axios(domain + '/usercheckuser', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const addAllProducts = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json', 
        },
        data,
    };
    return axios(domain + '/temporary/addproducts', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};